// const BASEURL = 'http://dep-java-app-qa.eba-2wwvqzn5.us-east-1.elasticbeanstalk.com/api';
// const BASEURL = 'http://dep-app-early-test.eba-7anyncfa.us-east-1.elasticbeanstalk.com/api';
// const BASEURL = 'https://dep-java-backend.azurewebsites.net/api'; // Dev
// const BASEURL = 'https://depbackend.azurewebsites.net/api'; // Demo
// const BASEURL = 'http://localhost:5000/api';
// const BASEURL = 'https://0a1c2f34-1z6b-fd98-b746-848s1e00e3ae.dataenterpriseplatform.click/api';
// const BASEURL = process.env.REACT_APP_API_URI;
// const BASEURL = 'http://dep-snowpark-java-backend-env.us-east-1.elasticbeanstalk.com/api';

// const BASEURL = 'http://x360-middleware-env.eba-kgm4kqvf.us-east-1.elasticbeanstalk.com/api';

const BASEURL = 'http://dep-middleware-env.eba-pe3djn2d.us-east-1.elasticbeanstalk.com/api';

// export const AWS_CONFIG_TYPE = process.env.REACT_APP_AWS_CONFIG_TYPE === 'true';
export const AWS_CONFIG_TYPE = true;
// export const AWS_CONFIG_TYPE = false;

export const OPEN_AI_URL = 'https://depgenopenai.eastus.cloudapp.azure.com';

export const AWS_UI_URL = 'https://nagarro.dataenterpriseplatform.click/';
export const AZURE_UI_URL = 'https://dataengplatform.com/';
export const SNOWFLAKE_UI_URL = 'http://dep-snowpark-react-frontend-env.us-east-1.elasticbeanstalk.com/';

// const AWSURL = 'http://dep-java-app-qa.eba-2wwvqzn5.us-east-1.elasticbeanstalk.com/api';
// const AZUREURL = 'https://dep-java-backend.azurewebsites.net/api';
// const SNOWFLAKEURL = 'http://dep-snowpark-java-backend-env.us-east-1.elasticbeanstalk.com/api';

export default BASEURL;
