import React, { useContext, useEffect, useState } from 'react';
import { Tree } from 'antd';
import { getAllClientsApi, getClientByUserGroup } from "../api's/ClientApi";
import { AuthContext } from '../contexts/AuthProvider';
import { getDirectoryData } from "../api's/ChatGPTApi";
import { AWS_CONFIG_TYPE } from '../BaseUrl';
import { WorkflowContext } from '../contexts/WorkflowProvider';

const TreeViewDirectory = ({ setSelectedFilePath, formField, setFormField, disableForm, setDisableForm }) => {
  const { DirectoryTree } = Tree;

  //   const treeData = [
  //     {
  //       title: 'parent 0',
  //       key: '0-0',
  //       children: [
  //         {
  //           title: 'leaf 0-0',
  //           key: '0-0-0',
  //           isLeaf: true,
  //         },
  //         {
  //           title: 'leaf 0-1',
  //           key: '0-0-1',
  //           isLeaf: true,
  //         },
  //       ],
  //     },
  //     {
  //       title: 'parent 1',
  //       key: '0-1',
  //       children: [
  //         {
  //           title: 'leaf 1-0',
  //           key: '0-1-0',
  //           isLeaf: true,
  //         },
  //         {
  //           title: 'leaf 1-1',
  //           key: '0-1-1',
  //           isLeaf: true,
  //         },
  //       ],
  //     },
  //     {
  //       title: 'parent 2',
  //       key: '0-2',
  //       children: [],
  //     },
  //   ];
  const [treeData, setTreeData] = useState([]);
  const [allClient, setAllClient] = useState('');
  const { userRole, userGroup } = useContext(AuthContext);
  const { workflow, setWorkflow } = useContext(WorkflowContext);

  const onSelect = (keys, info) => {
    // DON'T REMOVE THIS COMMENT //  console.log('Trigger Select', keys, info);
    if (info.node.isLeaf === true && !disableForm) {
      // console.log(info.node.filepath);
      setSelectedFilePath(info.node.filepath);
      setFormField({
        ...formField,
        path: info.node.filepath,
        innerPath: AWS_CONFIG_TYPE ? `${info.node.filepath}` : `dep-develop/${info.node.filepath}`,
      });
    }
  };

  const onExpand = (keys, info) => {
    // DON'T REMOVE THIS COMMENT // console.log('Trigger Expand', keys, info);
    if (info.expanded === true) {
      handleGetChildren(info.node.key, info.node.filepath);
    }
  };

  const handleGetChildren = async (key, filepath) => {
    const newTreeData = [...treeData];

    const data = {
      filepath,
      cloudEnvironment: workflow.cloudEnvironment,
    };
    const response = await getDirectoryData(data);

    const updateTreeDataRecursively = (dataToUpdate, keyToUpdate, newData) => {
      for (let i = 0; i < dataToUpdate.length; i++) {
        if (dataToUpdate[i].key === keyToUpdate) {
          dataToUpdate[i].children = newData.map((item, idx) => ({
            key: `${keyToUpdate}-${idx}`,
            title: item.filename,
            filepath: item.filepath,
            isLeaf: !item.folder,
            children: [],
            ...item,
          }));
        } else if (dataToUpdate[i].children) {
          updateTreeDataRecursively(dataToUpdate[i].children, keyToUpdate, newData);
        }
      }
    };

    updateTreeDataRecursively(newTreeData, key, response.data);
    setTreeData(newTreeData);
  };

  const getClient = async () => {
    const response = userRole === 'SUPER_USER' ? await getAllClientsApi() : await getClientByUserGroup(userGroup);
    if (response.status === 200) {
      const clients = response.data.filter((el) => el.status === 'approved');
      setAllClient(clients);
      const newTreeData = clients.map((client, index) => ({
        title: client.client_name,
        key: index.toString(),
        isLeaf: false,
        filepath: client.client_name,
        children: [],
      }));
      setTreeData(newTreeData);
      // console.log({ clients });
    }
  };

  useEffect(() => {
    getClient();
  }, []);

  return (
    <>
      <DirectoryTree
        style={{ fontSize: '18px' }}
        multiple
        defaultExpandAll={false}
        onSelect={onSelect}
        onExpand={onExpand}
        treeData={treeData}
      />
    </>
  );
};
export default TreeViewDirectory;
